<template>
  <a-spin :spinning="spinning">
    <div class="">
      <div class="">
        <!-- 操作 -->

        <!-- 数据部分 -->
        <!-- <div class="my-md">
          <a-alert :type="'info'" :showIcon="true">
            <template slot="message">
              <span
                v-html="l('GridSelectedXItemsTips', selectedRowKeys.length)"
              ></span>
              <a @click="restCheckStatus()" class="ml-md">{{
                l("ClearEmpty")
              }}</a>
              <a-divider type="vertical"></a-divider>
              <a @click="clearFilterAndRefresh()">{{ l("Refresh") }}</a>
            </template>
          </a-alert>
        </div> -->
        <!-- <react-table
          ref="listTable"
          :table-data="data"
          :total-items="totalItems"
          :columns="columns"
          :showTotal="showTotalFun"
          :actions-type="actionsType"
          :hide-row-selection="true"
          :current-page="pageNumber"
          @emitRefreshData="getData"
          @emitSortData="updateSortData"
          @emitSelectChange="updateSelectChange"
          @emitOnPageChange="updatePageChange"
          @emitShowSizeChange="updateShowSizeChange"
        > -->
        <ele-table
          :columns="columns"
          :table-data="data"
          :total-items="totalItems"
          :is-full="false"
          :actionsType="actionsType"
          :current-page="pageNumber"
          :hide-row-selection="true"
          @emitRefreshData="getData"
          @emitSelectChange="updateSelectChange"
          @emitOnPageChange="updatePageChange"
          @emitShowSizeChange="updateShowSizeChange"
        >
          <a-row class="opeattion-container" type="flex" align="middle">
            <a-col :span="12">
              <a-input-search
                name="filterText"
                @search="getData"
                :placeholder="l('SearchWithThreeDot')"
                enterButton
                v-model="filterText"
              />
            </a-col>
            <a-col :span="10" :offset="2">
              <a @click="createOrEdit(null, 'create')">
                <a-icon type="plus" /> 添加
              </a>
              <a-divider type="vertical"></a-divider>
              <a :title="l('Refresh')" @click="clearFilterAndRefresh()">
                <a-icon type="reload"
              /></a>
            </a-col>
          </a-row>
        </ele-table>
        <!-- <a-table
          @change="handleChange"
          :rowSelection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }"
          :pagination="false"
          :columns="columns"
          :rowKey="(data) => data.id"
          :dataSource="data"
        >
          <span slot="actions" slot-scope="text, record">
            <a class="table-edit" @click="createOrEdit(record)">
              <a-icon type="edit" />修改
            </a>
            <a-popconfirm
              placement="top"
              :okText="l('Ok')"
              :cancelText="l('Cancel')"
              @confirm="remove(record)"
            >
              <template slot="title">
                {{
                  l(
                    "RemoveUserFromOuWarningMessage",
                    record.showName,
                    selectTree.text
                  )
                }}
              </template>
              <a class="table-delete">
                <a-icon type="delete" />{{ l("Delete") }}</a
              >
            </a-popconfirm>
          </span>
        </a-table> -->
      </div>
    </div>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { GeneralFieldsServiceProxy } from "../services/general-fields-proxies";
import { ModalHelper } from "@/shared/helpers";
import Bus from "@/shared/bus/bus";
import { CommonServiceProxy } from "@/shared/common-service";
import EleTable from "@/components/ele-table";
import ReactModify from "@/components/react-modify";

export default {
  name: "general-fields-panel",
  mixins: [AppComponentBase],
  components: {
    EleTable,
  },
  data() {
    return {
      spinning: false,
      commonService: null,
      generalFieldsService: null,
      selectedRowKeys: [],
      showName: "",
      key: "",
      filterText: "",
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      request: { sorting: "", maxResultCount: 50, skipCount: 0 },
      // 用户表格
      columns: [
        {
          title: "变量名称",
          dataIndex: "showName",
          // width: 165,
          align: "center",
          scopedSlots: { customRender: "showName" },
        },
        {
          title: "[Key]",
          dataIndex: "key",
          // width: 230,
          align: "center",
          scopedSlots: { customRender: "key" },
        },
        {
          title: "最大长度",
          dataIndex: "maxLength",
          // width: 70,
          align: "center",
          scopedSlots: { customRender: "maxLength" },
        },
        {
          title: "排序",
          dataIndex: "sort",
          width: 80,
          align: "center",
          scopedSlots: { customRender: "sort" },
        },
        {
          title: this.l("Actions"),
          dataIndex: "actions",
          width: 105,
          align: "center",
          scopedSlots: { customRender: "actions" },
        },
      ],
      // 用户数据
      data: [],
      actionsType: {},
    };
  },
  computed: {},
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.initActionsType();
    this.generalFieldsService = new GeneralFieldsServiceProxy(
      this.$apiUrl,
      this.$api
    );
    // 接受树结构传过来的选中item
    Bus.$on("selectedNode", this.getTree);
  },
  beforeDestroy() {
    Bus.$off("selectedNode");
  },
  methods: {
    /**
     * 选中树结构
     */
    getTree(data) {
      this.selectTree = data;
      this.getData();
    },
    /**
     * 拉取数据
     */
    async getData() {
      this.spinning = true;
      try {
        let res =
          await this.generalFieldsService.generalFieldsOfAgreementContentGetPaged(
            this.showName,
            this.key,
            this.selectTree.id,
            this.filterText,
            this.request.sorting,
            this.request.maxResultCount,
            this.request.skipCount
          );
        this.spinning = false;
        this.data = res.items.map((item) => {
          return {
            ...item,
          };
        });
        // console.log(this.data)
        this.totalItems = res.totalCount;
        this.pagerange = [
          (this.pageNumber - 1) * this.request.maxResultCount + 1,
          this.pageNumber * this.request.maxResultCount,
        ];
        this.totalPages = Math.ceil(
          res.totalCount / this.request.maxResultCount
        );
      } catch (error) {
        this.spinning = false;
      }
    },
    // 初始化需要显示的func btn
    initActionsType() {
      let _this = this;
      let obj = {
        type: "",
        isShow: true,
        fns: {
          dropList: [
            {
              granted: true,
              name: this.l("修改"),
              icon: "profile",
              fn: (data) => {
                _this.createOrEdit(data, "edit");
              },
            },
          ],
          // delete: {
          //   granted: true,
          //   name: this.l("Delete"),
          //   fn: (data) => {
          //     _this.deleteItem(data.id);
          //   },
          // },
        },
      };
      this.actionsType = obj;
    },
    // 清除条件并刷新
    clearFilterAndRefresh() {
      this.request = { sorting: "", maxResultCount: 50, skipCount: 0 };
      this.filterText = "";
      this.showName = "";
      this.key = "";
      this.getData();
    },
    // 排序更新data
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.request.sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    // 分页事件
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    // table选择事件
    updateSelectChange(newV) {
      let { selectedRowKeys } = newV;
      this.selectedRowKeys = selectedRowKeys;
    },
    // 分页
    updatePageChange(newV) {
      let { page } = newV;
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData();
    },
    updateShowSizeChange(newV) {
      let { size } = newV;
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.getData();
    },
    // 删除字段
    async deleteItem(id) {
      // console.log(item);
      // const { id } = item;
      try {
        await this.generalFieldsService.generalFieldsOfAgreementContentDelete(
          id
        );
        this.$notification["success"]({
          message: this.l("SuccessfullyRemoved"),
        });
        this.clearFilterAndRefresh();
        Bus.$emit("reloadOrganizationUnitTree", true);
      } catch (error) {}
    },
    // 增加字段
    createOrEdit(item, type) {
      const list = [
        {
          label: "名称",
          value: "showName",
          type: "commonInput",
          disabled: false,
          required: true,
          rules: [{ max: 128, message: this.l("MaxLength") }],
          changeFn: () => {},
        },
        {
          label: "排序",
          value: "sort",
          type: "commonInput",
          disabled: false,
          required: true,
          rules: [{ max: 128, message: this.l("MaxLength") }],
          changeFn: () => {},
        },
        {
          label: "键值",
          value: "key",
          type: "commonInput",
          disabled: false,
          required: true,
          rules: [{ max: 128, message: this.l("MaxLength") }],
          changeFn: () => {},
        },
        {
          label: "最大长度",
          value: "maxLength",
          type: "commonInput",
          disabled: false,
          required: true,
          rules: [{ max: 128, message: this.l("MaxLength") }],
          changeFn: () => {},
        },
      ];
      let reqParams = [];
      if (item && item.id) {
        console.log(item);
        reqParams.push({ key: "id", value: item.id });
      }
      ModalHelper.create(
        ReactModify,
        {
          params: {
            type: type, // edit,preview,create
            data: list,
            reqParams: [
              ...reqParams,
              {
                key: "generalFieldsOfAgreementContentGroupId",
                value: this.selectTree.id,
              },
            ],
            reqUrl: {
              getById:
                "/api/services/app/GeneralFieldsOfAgreementContent/GetEditById",
              save: "/api/services/app/GeneralFieldsOfAgreementContent/Save",
            },
            otherParams: {},
            getItemfn: async (res, form, options) => {
              form.setFieldsValue({ ...res });
              if (options) {
                // ...
              }
            },
            submitfn: async (values, queryParams) => {
              let obj = {};
              queryParams.reqParams.map((item) => {
                obj[item.key] = item.value;
              });
              Object.assign(values, obj);
              // console.log(values)
              try {
                await this.commonService.post({
                  url: queryParams.reqUrl.save,
                  params: { ...values },
                });
                return true;
              } catch (error) {
                return false;
              }
            },
          },
        },
        {
          isChange: true,
          width: "800px",
        }
      ).subscribe((res) => {
        if (res) {
          this.clearFilterAndRefresh();
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.user-container {
  border: 1px solid #e8e8e8;
  margin: 20px;
  padding: 20px;
}
.opeattion-container {
  margin: 20px 0;
  margin-bottom: 0;
  // padding: 0 20px;
}
.pagination {
  margin: 10px auto;
  text-align: right;
}
</style>
