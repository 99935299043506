<template>
  <section class="organization-container">
    <!-- <page-header title="合同内容通用字段管理"></page-header> -->
    <a-row :gutter="8">
      <a-col
        :span="8"
        class="organization-unit-tree-panel"
        style="width: 30%; overflow-x: auto"
      >
        <general-fields-group-panel
          @selectedNode="selectedNodeFunc"
        ></general-fields-group-panel>
      </a-col>
      <a-col :span="16" class="organization-unit-tree-panel" style="width: 70%">
        <!-- <a-row :gutter="8" class="units-header">
          <a-col :span="24" class="title">
            <a-icon type="team" />
            <span v-if="selectedTree && selectedTree.hasOwnProperty('id')">{{
              selectedTree.displayName
            }}</span>
          </a-col>
        </a-row> -->
        <a-row class="form">
          <!-- 暂无数据 -->
          <div
            class="no-data"
            v-if="!selectedTree || !selectedTree.hasOwnProperty('id')"
          >
            <p>{{ l("请选择一个通用字段组以浏览其成员") }}</p>
          </div>
          <a-tabs
            defaultActiveKey="1"
            v-if="selectedTree && selectedTree.hasOwnProperty('id')"
          >
            <!-- 用户 -->
            <a-tab-pane :tab="selectedTree.text + '-通用字段'" key="1">
              <general-fields-panel></general-fields-panel>
            </a-tab-pane>
          </a-tabs>
        </a-row>
      </a-col>
    </a-row>
  </section>
</template>

<script>
import { PagedListingComponentBase } from "@/shared/component-base";
import GeneralFieldsGroupPanel from "./general-fields-group-panel/general-fields-group-panel";
import GeneralFieldsPanel from "./general-fields-panel/general-fields-panel";

export default {
  name: "general-fields",
  mixins: [PagedListingComponentBase],
  components: {
    GeneralFieldsGroupPanel,
    GeneralFieldsPanel,
  },
  data() {
    return {
      // 选择的树结构
      selectedTree: {},
    };
  },
  mounted() {
    this.selectedTree = {};
  },
  methods: {
    /**
     * 选择树结构
     */
    selectedNodeFunc(data) {
      this.selectedTree = data;
    },
  },
};
</script>

<style scoped lang="less">
.organization-container {
  min-height: 600px;
}
.organization-unit-tree-panel {
  background-color: #fff;
  border: 1px solid #e8e8e8;
}
.units-header {
  height: 50px;
  border-bottom: 1px solid #e8e8e8;
  > .title {
    line-height: 50px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    margin-left: 20px;
  }
  p {
    line-height: 50px;
    &.left {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
      margin-left: 20px;
    }
    a {
      margin-left: 10px;
    }
  }
}
/deep/.ant-tabs-bar {
  margin: 0 20px;
}
// 暂无数据
.no-data {
  border: 1px solid #e8e8e8;
  margin: 20px;
  p {
    text-align: center;
    margin-bottom: 0;
    line-height: 120px;
  }
}
</style>
