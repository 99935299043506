import "rxjs/add/operator/finally";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import {
  GeneralFieldsOfAgreementContentGetListDTO,
  GeneralFieldsOfAgreementContentGetPagedDTO,
  GeneralFieldsOfAgreementContentGroupGetSelectDataDTO,
  GeneralFieldsOfAgreementContentGroupSaveDTO,
  GeneralFieldsOfAgreementContentSaveDTO,
} from "./model";

import { CommonProcess } from "@/shared/common-process";

const cp = new CommonProcess();

export class GeneralFieldsServiceProxy {
  private instance: AxiosInstance;
  private baseUrl: string;
  protected jsonParseReviver:
    | ((key: string, value: any) => any)
    | undefined = undefined;

  constructor(baseUrl?: string, instance?: AxiosInstance) {
    this.instance = instance ? instance : axios.create();
    this.baseUrl = baseUrl ? baseUrl : "";
  }

  /**
   * 合同内容通用字段---获取全部列表
   */
  generalFieldsOfAgreementContentGetList(): Promise<
    GeneralFieldsOfAgreementContentGetListDTO
  > {
    let url_ =
      this.baseUrl +
      `/api/services/app/GeneralFieldsOfAgreementContent/GetList`;
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        Accept: "text/plain",
      },
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }
  /**
   * 合同内容通用字段---获取全部列表
   */
  generalFieldsOfAgreementContentGetListByTemplateId(
    id
  ): Promise<GeneralFieldsOfAgreementContentGetListDTO> {
    let url_ =
      this.baseUrl +
      `/api/services/app/GeneralFieldsOfAgreementContent/GetListByTemplate?templateId=` +
      id;
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        Accept: "text/plain",
      },
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }

  /**
   * 合同内容通用字段---分页
   * @param showName
   * @param key
   * @param groupId
   * @param filterText
   * @param sorting
   * @param maxResultCount
   * @param skipCount
   */
  generalFieldsOfAgreementContentGetPaged(
    showName: string,
    key: string,
    groupId: string,
    filterText: string,
    sorting: string,
    maxResultCount: number,
    skipCount: number
  ): Promise<GeneralFieldsOfAgreementContentGetPagedDTO> {
    let url_ =
      this.baseUrl +
      `/api/services/app/GeneralFieldsOfAgreementContent/GetPaged?showName=${showName}&key=${key}&groupId=${groupId}&filterText=${filterText}&sorting=${sorting}&maxResultCount=${maxResultCount}&skipCount=${skipCount}`;
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        Accept: "text/plain",
      },
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }

  /**
   * 合同内容通用字段---获取全部
   * @param name
   * @param filterText
   * @param sorting
   * @param maxResultCount
   * @param skipCount
   */
  generalFieldsOfAgreementContentGetSelectData(): Promise<
    GeneralFieldsOfAgreementContentGroupGetSelectDataDTO
  > {
    let url_ =
      this.baseUrl +
      `/api/services/app/GeneralFieldsOfAgreementContent/GetSelectData`;
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        Accept: "text/plain",
      },
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }

  /**
   * 合同内容通用字段---增改
   * @param body
   */
  generalFieldsOfAgreementContentSave(
    body: GeneralFieldsOfAgreementContentSaveDTO | undefined
  ): Promise<any> {
    let url_ =
      this.baseUrl + `/api/services/app/GeneralFieldsOfAgreementContent/Save`;
    url_ = url_.replace(/[?&]$/, "");

    const content_ = body;

    let options_ = <AxiosRequestConfig>{
      data: content_,
      method: "POST",
      url: url_,
      headers: {
        Accept: "text/plain",
      },
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }

  /**
   * 合同内容通用字段---删除
   * @param id
   */
  generalFieldsOfAgreementContentDelete(id: number | undefined): Promise<void> {
    let url_ =
      this.baseUrl +
      "/api/services/app/GeneralFieldsOfAgreementContent/Delete?";
    if (id === null) {
      throw new Error("The parameter 'id' cannot be null.");
    } else if (id !== undefined) {
      url_ += "id=" + encodeURIComponent("" + id) + "&";
    }
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "DELETE",
      url: url_,
      headers: {},
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }

  /**
   * 合同内容通用字段组---分页
   * @param name
   * @param filterText
   * @param sorting
   * @param maxResultCount
   * @param skipCount
   */
  generalFieldsOfAgreementContentGroupGetPaged(
    name: string,
    filterText: string,
    sorting: string,
    maxResultCount: number,
    skipCount: number
  ): Promise<GeneralFieldsOfAgreementContentGetPagedDTO> {
    let url_ =
      this.baseUrl +
      `/api/services/app/GeneralFieldsOfAgreementContentGroup/GetPaged?name=${name}&filterText=${filterText}&sorting=${sorting}&maxResultCount=${maxResultCount}&skipCount=${skipCount}`;
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        Accept: "text/plain",
      },
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }

  /**
   * 合同内容通用字段组---获取全部
   * @param name
   * @param filterText
   * @param sorting
   * @param maxResultCount
   * @param skipCount
   */
  generalFieldsOfAgreementContentGroupGetSelectData(): Promise<
    GeneralFieldsOfAgreementContentGroupGetSelectDataDTO
  > {
    let url_ =
      this.baseUrl +
      `/api/services/app/GeneralFieldsOfAgreementContentGroup/GetSelectData`;
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        Accept: "text/plain",
      },
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }

  /**
   * 合同内容通用字段组---增改
   * @param body
   */
  generalFieldsOfAgreementContentGroupSave(
    body: any | undefined
  ): Promise<any> {
    let url_ =
      this.baseUrl +
      `/api/services/app/GeneralFieldsOfAgreementContentGroup/Save`;
    url_ = url_.replace(/[?&]$/, "");

    const content_ = body;

    let options_ = <AxiosRequestConfig>{
      data: content_,
      method: "POST",
      url: url_,
      headers: {
        Accept: "text/plain",
      },
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }

  /**
   * 合同内容通用字段组---删除
   * @param id
   */
  generalFieldsOfAgreementContentGroupDelete(
    id: number | undefined
  ): Promise<void> {
    let url_ =
      this.baseUrl +
      "/api/services/app/GeneralFieldsOfAgreementContentGroup/Delete?";
    if (id === null) {
      throw new Error("The parameter 'id' cannot be null.");
    } else if (id !== undefined) {
      url_ += "id=" + encodeURIComponent("" + id) + "&";
    }
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "DELETE",
      url: url_,
      headers: {},
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }

  generalFieldsOfAgreementContentGroupGetEditById(id: string): Promise<any> {
    let url_ =
      this.baseUrl +
      `/api/services/app/GeneralFieldsOfAgreementContentGroup/GetEditById?id=${id}`;
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        Accept: "text/plain",
      },
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }
}
