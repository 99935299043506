<template>
  <a-spin @click="clearMenu" :spinning="spinning">
    <a-row :gutter="8" class="header">
      <a-col :span="14">
        <p class="left">
          <a-icon type="share-alt" />
          通用字段组
        </p>
      </a-col>
      <a-col :span="10">
        <p>
          <a
            @click="createOrEdit(null, 'create')"
            v-if="
              isGranted('Pages.GeneralFieldsOfAgreementContentGroup.Create')
            "
          >
            <a-icon type="plus" /> 添加
          </a>
          <a-divider type="vertical"></a-divider>
          <a :title="l('Refresh')" @click="getData()">
            <a-icon type="reload"
          /></a>
        </p>
      </a-col>
    </a-row>
    <a-row class="tree">
      <a-tree :treeData="treeData" showIcon defaultExpandAll @select="onSelect">
        <!-- @rightClick="onRightClick"
      > -->
        <a-icon type="folder" slot="folder" />
        <a-icon type="file" slot="file" />
        <a-icon type="folder-open" slot="folder-open" />
        <template slot="custom" slot-scope="{ expanded }">
          <a-icon :type="expanded ? 'folder-open' : 'folder'" />
        </template>
      </a-tree>
      <div :style="tmpStyle" class="right-click-item" v-if="NodeTreeItem">
        <ul>
          <li
            @click="createOrEdit(curModifyItem, 'edit')"
            v-if="isGranted('Pages.GeneralFieldsOfAgreementContentGroup.Edit')"
          >
            <a-icon type="edit" />
            <span>
              {{ l("Edit") }}
            </span>
          </li>
          <li
            @click.stop="deleteProp($event)"
            v-if="
              isGranted('Pages.GeneralFieldsOfAgreementContentGroup.Delete')
            "
          >
            <a-popconfirm
              placement="right"
              :title="l('ConfirmDeleteWarningMessage')"
              @confirm="deleteconfirm"
              @cancel="clearMenu"
              :okText="l('Ok')"
              :cancelText="l('Cancel')"
            >
              <a-icon type="delete" />
              <span>
                {{ l("Delete") }}
              </span>
            </a-popconfirm>
          </li>
        </ul>
      </div>
    </a-row>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { GeneralFieldsServiceProxy } from "../services/general-fields-proxies";
import { CommonServiceProxy } from "@/shared/common-service";
import { ModalHelper } from "@/shared/helpers";
import Bus from "@/shared/bus/bus";
import ReactModify from "@/components/react-modify";

export default {
  name: "organization-unit-tree-panel",
  mixins: [AppComponentBase],
  data() {
    return {
      spinning: false,
      commonService: null,
      generalFieldsService: null,
      _ouData: [],
      treeData: [],
      NodeTreeItem: null, // 右键菜单
      tmpStyle: "",
      // 选中的item
      activedNode: {},
      // 右键选中的项
      curModifyItem: {},
      naturalFormList: [],
      unNaturalFormList: [],
    };
  },
  created() {
    this.generalFieldsService = new GeneralFieldsServiceProxy(
      this.$apiUrl,
      this.$api
    );
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.$nextTick(() => {
      Bus.$on("reloadOrganizationUnitTree", (data) => {
        if (data) {
          this.getData();
        }
      });
    });
    this.getData();
    // console.log(this.activedNode);
  },
  beforeDestroy() {
    Bus.$off("reloadOrganizationUnitTree");
  },
  methods: {
    async getData() {
      this.spinning = true;
      try {
        let res =
          await this.generalFieldsService.generalFieldsOfAgreementContentGroupGetSelectData();
        this.spinning = false;
        //   console.log(res);
        this._ouData = res;
        this.treeData = this.treeDataMap();
        //   console.log(this.treeData);
      } catch (error) {
        this.spinning = false;
      }
    },
    /**
     * 重组Tree数据
     */
    treeDataMap() {
      const ouDtataParentIsNull = this._ouData.map((item) => {
        item.id = item.value;
        return { ...item, id: item.value };
      });
      // console.log(ouDtataParentIsNull);
      return ouDtataParentIsNull.map((item) =>
        this._recursionGenerateTree(item)
      );
    },

    /**
     * 递归重组特性菜单为nzTree数据类型
     * @param item 组织机构项
     */
    _recursionGenerateTree(item) {
      // 叶子节点
      const childs = [];
      // 父节点 无返回undefined
      //   const parentOu = _.find(this._ouData, (p) => p.id === item.parentId);
      const _treeNode = {
        title: item.text,
        key: item.value,
        isLeaf: childs && childs.length <= 0,
        slots: {
          icon: childs && childs.length > 0 ? "folder" : "file",
        },
        expanded: true,
        isMatched: true,
        dto: item,
        children: [],
      };
      return _treeNode;
    },
    /**
     * 选中item
     */
    onSelect(selectedKeys) {
      this.activedNode = this._ouData.find(
        (item) => item.value == selectedKeys[0]
      );
      this.$emit("selectedNode", this.activedNode);
      this.$nextTick(() => {
        Bus.$emit("selectedNode", this.activedNode);
      });
      // console.log("selected", this.activedNode);
    },
    /**
     * 展开
     */
    onExpand(onExpandarr) {
      this.resetExpandIcon(this.treeData);
      for (let i in onExpandarr) {
        this.setExpandIcon(onExpandarr[i], this.treeData);
      }
    },
    /**
     * 还原图标
     */
    resetExpandIcon(item) {
      for (let i in item) {
        if (!item[i].isLeaf) {
          item[i].slots.icon = "folder";
        }
        if (item[i].children.length) {
          this.resetExpandIcon(item[i].children);
        }
      }
    },
    /**
     * 设置图标
     */
    setExpandIcon(id, item) {
      for (let i in item) {
        if (item[i].key == id) {
          item[i].slots.icon = "folder-open";
        }
        if (item[i].children.length) {
          for (let {} in item[i].children) {
            this.setExpandIcon(id, item[i].children);
          }
        }
      }
    },
    /**
     * 右键事件
     */
    onRightClick({ event, node }) {
      // console.log(event)
      // console.log(node)
      if (!this.activedNode.id) return;
      this.curModifyItem = {
        name: node.title,
        id: node.eventKey,
      };
      const x =
        event.currentTarget.offsetLeft + event.currentTarget.clientWidth;
      const y = event.currentTarget.offsetTop;
      this.NodeTreeItem = {
        pageX: x,
        pageY: y,
        id: node._props.eventKey,
        title: node._props.title,
        parentOrgId: 0,
      };
      this.tmpStyle = {
        position: "absolute",
        maxHeight: 40,
        textAlign: "center",
        left: `${x + 10 - 0}px`,
        top: `${y + 6 - 0}px`,
        display: "flex",
        flexDirection: "row",
      };
    },
    /**
     * 用于点击空白处隐藏增删改菜单
     */
    clearMenu() {
      this.NodeTreeItem = null;
    },
    async _getFormSelectList(type) {
      const res = await this.commonService.get({
        url: "/api/services/app/GeneralFieldsOfAgreementContentGroup/GetFormSelectList",
        params: { formType: type },
      });
      let arr = res.map((item) => {
        return {
          label: item.text,
          value: item.value.toLowerCase(),
        };
      });
      return arr;
    },
    async createOrEdit(item, type) {
      if (!this.naturalFormList.length) {
        this.naturalFormList = await this._getFormSelectList(0);
      }
      if (!this.unNaturalFormList.length) {
        this.unNaturalFormList = await this._getFormSelectList(1);
      }
      let myForm;
      let formObj = {
        label: "采集表单",
        value: "collectionFormId",
        type: "commonSelect",
        disabled: false,
        required: true,
        rules: [{ required: true, message: "请选择采集表单" }],
        defaultValue: "",
        defaultSelectList: [],
        changeFn: () => {},
      };
      let list = [
        {
          label: "名称",
          value: "name",
          type: "commonInput",
          disabled: false,
          required: true,
          rules: [{ max: 128, message: this.l("MaxLength") }],
          changeFn: () => {},
        },
        {
          label: "排序",
          value: "sort",
          type: "numberInput",
          disabled: false,
          defaultValue: 0,
          required: true,
          // rules: [{ max: 128, message: this.l("MaxLength") }],
          changeFn: () => {},
        },
        // {
        //   label: "数据来源",
        //   value: "dataSource",
        //   type: "commonSelect",
        //   disabled: false,
        //   required: true,
        //   rules: [{ required: true, message: "请选择数据来源" }],
        //   defaultValue: "",
        //   defaultSelectList: [
        //     { label: "数据库", value: 1 },
        //     { label: "表单", value: 2 },
        //   ],
        //   changeFn: (value) => {
        //     myForm.resetFields(["agreementType", "collectionFormId"]);
        //     if (value === 2 && list.length === 5) {
        //       list.push(formObj);
        //     } else if (value !== 2 && list.length === 6) {
        //       list.splice(list.length - 1, 1);
        //     }
        //   },
        // },
        // {
        //   label: "数据源Key",
        //   value: "sourceKey",
        //   type: "commonSelect",
        //   disabled: false,
        //   required: true,
        //   rules: [{ required: true, message: "请选择数据源Key" }],
        //   defaultValue: "",
        //   defaultSelectList: [
        //     { label: "项目(Project)", value: "Project" },
        //     { label: "个人(Personnel)", value: "Personnel" },
        //     { label: "客户(Customer)", value: "Customer" },
        //     { label: "组织架构(Organization)", value: "Organization" },
        //     { label: "采集表单(Form)", value: "Form" },
        //     // { label: '系统表单(SystemForm)', value: 'SystemForm' },
        //     // {
        //     //   label: "系统项目表单(SystemProjectForm)",
        //     //   value: "SystemProjectForm",
        //     // },
        //     // {
        //     //   label: "系统项目人员表单(SystemProjectPersonnelForm)",
        //     //   value: "SystemProjectPersonnelForm",
        //     // },
        //     { label: "其他(Other)", value: "Other" },
        //   ],
        //   changeFn: () => {},
        // },
        {
          label: "协议类型",
          value: "agreementType",
          type: "commonSelect",
          disabled: false,
          required: true,
          rules: [{ required: true, message: "请选择协议类型" }],
          defaultValue: "",
          defaultSelectList: [
            { label: "自然人", value: 0 },
            { label: "非自然人", value: 1 },
          ],
          changeFn: (v) => {
            myForm.resetFields(["collectionFormId"]);
            if (v === 0) {
              list[3].defaultSelectList = this.naturalFormList;
            } else if (v === 1) {
              list[3].defaultSelectList = this.unNaturalFormList;
            }
            // if (list.length === 6) {
            //   myForm.resetFields(["collectionFormId"]);
            //   if (v === 0) {
            //     list[5].defaultSelectList = this.naturalFormList;
            //   } else if (v === 1) {
            //     list[5].defaultSelectList = this.unNaturalFormList;
            //   }
            // }
          },
        },
        formObj,
      ];
      let reqParams = [];
      if (item && item.id) {
        reqParams.push({ key: "id", value: item.id });
      }
      ModalHelper.create(
        ReactModify,
        {
          params: {
            type: type, // edit,preview,create
            data: list,
            reqParams: [...reqParams],
            reqUrl: {
              getById:
                "/api/services/app/GeneralFieldsOfAgreementContentGroup/GetEditById",
              save: "/api/services/app/GeneralFieldsOfAgreementContentGroup/Save",
            },
            otherParams: {},
            initFn: async (form) => {
              myForm = form;
            },
            getItemfn: async (res, form, options) => {
              if (res.dataSource === 2 && res.collectionFormId) {
                formObj.defaultSelectList =
                  res.agreementType === 0
                    ? this.naturalFormList
                    : this.unNaturalFormList;
                list.push(formObj);
              }
              this.$nextTick(() => {
                form.setFieldsValue({
                  ...res,
                  sort: res.sort.toString(),
                  collectionFormId: res.collectionFormId,
                });
              });
            },
            submitfn: async (values, queryParams) => {
              let obj = {};
              queryParams.reqParams.map((item) => {
                obj[item.key] = item.value;
              });
              Object.assign(values, obj, {
                sourceKey: "Form", // 表单
                dataSource: 2, // 表单
              });
              // console.log("submitfn:", values);
              try {
                await this.commonService.post({
                  url: queryParams.reqUrl.save,
                  params: { ...values },
                });
                return true;
              } catch (error) {
                return false;
              }
            },
          },
        },
        {
          isChange: true,
          width: "800px",
        }
      ).subscribe((res) => {
        if (res) {
          this.getData();
        }
      });
    },
    /**
     * 编辑组织机构
     */
    editUnit() {
      const canManageOrganizationTree = this.isGranted(
        "Pages.Administration.OrganizationUnits.ManageOrganizationTree"
      );
      if (!canManageOrganizationTree) {
        this.$notification["error"]({
          message: this.l("YouHaveNoOperatingPermissions"),
        });
        return;
      }
      if (this.activedNode.id) {
        const ouPars = {
          id: parseInt(this.activedNode.id),
          displayName: this.activedNode.displayName,
        };
        ModalHelper.create(CreateOrEditOrganiaztionUnit, {
          organizationUnit: ouPars,
        }).subscribe((res) => {
          if (res) {
            setTimeout(() => {
              this.getData();
            }, 1500);
          }
        });
      }
    },
    /**
     * 添加子节点
     * @param node 当前选中节点
     */
    addSubUnit() {
      const canManageOrganizationTree = this.isGranted(
        "Pages.Administration.OrganizationUnits.ManageOrganizationTree"
      );
      if (!canManageOrganizationTree) {
        this.$notification["error"]({
          message: this.l("YouHaveNoOperatingPermissions"),
        });
        return;
      }
      if (this.activedNode.id) {
        this.addUnit(this.activedNode);
      }
    },
    /**
     * 删除组织事件
     */
    deleteProp(e) {
      e.stopPropagation();
    },
    /**
     * 删除组织结构
     */
    deleteconfirm() {
      const canManageOrganizationTree = this.isGranted(
        "Pages.Administration.OrganizationUnits.ManageOrganizationTree"
      );
      if (!canManageOrganizationTree) {
        this.$notification["error"]({
          message: this.l("YouHaveNoOperatingPermissions"),
        });
        return;
      }
      // console.log(this.curModifyItem)
      if (this.curModifyItem.id) {
        this.spinning = true;
        this.generalFieldsService
          .generalFieldsOfAgreementContentGroupDelete(this.curModifyItem.id)
          .then((_) => {
            this.$notification["error"]({
              message: this.l("SuccessfullyDeleted"),
            });
            this.spinning = false;
            this.clearMenu();
            setTimeout(() => {
              this.getData();
            }, 500);
          })
          .catch((_) => {
            this.spinning = false;
          });
      }
    },
  },
};
</script>

<style scoped lang="less">
.header {
  height: 50px;
  border-bottom: 1px solid #e8e8e8;
  p {
    line-height: 50px;
    &.left {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
      margin-left: 20px;
    }
    a {
      margin-left: 10px;
    }
  }
}
.tree {
  // margin: 20px;
  margin-bottom: 60px;
}
.right-click-item {
  position: relative;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  -webkit-transform: translate3d(0, 0, 0);
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      clear: both;
      margin: 0;
      padding: 5px 12px;
      color: rgba(0, 0, 0, 0.65);
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      white-space: nowrap;
      cursor: pointer;
      transition: all 0.3s;
      text-align: left;
    }
  }
}
</style>
